module.exports = {
	AMPLITUDE_API_KEY: '2bed555143d65d86d06da20a02cc911a',
	AUTH_SERVER_URL: '/auth',
	AUTH_API_URL: '/api',
	AUTH_API_APPLICATION_KEY: 'smartbuildings',
	AUTH_API_APPLICATION_SECRET: '0c11d3e1-0da5-11e8-baf6-f1ce3d466cc4',
	BING_MAPS_AUTOSUGGEST_URL: 'https://dev.virtualearth.net/REST/v1/Autosuggest',
	BING_MAPS_LOCATIONS_URL: 'https://dev.virtualearth.net/REST/v1/Locations',
	BING_MAPS_API_KEY:
		'AqFSRi2TgMfOZfjc2vfSMVPlC8T9QTGyx2vuT-jjJdP5e7s1Uc9KvmSiKBjVYNI0',
	CHARGEBEE_URL: 'https://js.chargebee.com/v2/chargebee.js',
	CHARGEBEE_SITE: 'ecobee',
	BUGSNAG_API_KEY: '74a9a716d6d02bde54f05d70dd470e43',
	GOOGLE_ANALYTICS_TRACKING_ID: 'UA-148378333-2',
	GOOGLE_TAG_MANAGER_AUTH: '5pkYMFX5lsqPTgqgCQvzKA',
	GOOGLE_TAG_MANAGER_ENV: 'env-6',
	GOOGLE_TAG_MANAGER_ID: 'GTM-PHLT7RK',
	INTERCOM_APP_ID: 'jvd56aod',
	ENV: 'staging',
	LD_CLIENT_ID: '5cc9e6ba891264081d0a2f33',
	ROUTER_BASE_NAME: '/',
	PAGINATION_RESULTS_PER_PAGE: 25,
	USER_PREFERENCES_DEFAULT: {
		useCelsius: false,
	},
}
